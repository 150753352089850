/* stylelint-disable selector-max-universal,selector-max-compound-selectors, declaration-no-important */
.is-page-loading * {
  transition: none !important;
}


// Helpers
.nobr {
  white-space: nowrap;
}

// Text align
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}

.semibold {
  font-weight:600;
}

.centered {
  margin-left: auto;
  margin-right: auto;
}

.text-no-transform {
  text-transform: none;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-decoration-none {
  text-decoration: none;
}
.text-white {
  color: white!important;
}
// img
.img-full {
  display: block;
  width: 100%;
}

.img-max {
  display: block;
  max-width: 100%;
}
.img--center {
  margin-left: auto;
  margin-right: auto;
}

.list--img {
  list-style: none;
  padding:0;
  margin:0;
}

.list--arrow {
  list-style: none;
  > li {
    position: relative;
    padding-left: spacing('md');
    &::before {
      content:'»';
      position: absolute;
      line-height: 1.4;
      // margin-right: spacing('md');
      top:0;
      left:0;
    }
  }
  > li + li {
    margin-top: spacing('xs');
  }
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.compat-object-fit {
  background-size: cover !important;
  background-position: center center !important;
  img {
    opacity: 0;
    visibility: hidden;
  }
}


// COLORS!
@each $color, $hex in $colors {
  .text--#{$color} {
    color: $hex;
  }

  .bg--#{$color} {
    background: $hex;
  }
  
  .bg-gradient--#{$color} {
    background: transparent linear-gradient(90deg, $hex 40%, rgba(255,255,255,0) 75%) 0% 0% no-repeat padding-box; 
  }
}

//GRADIENTS
@each $name, $hex in $gradients {
  .gradient--#{$name} {
    background: #{$hex} no-repeat 0 0;
  }
}
.text--max-width {
  max-width:50ch;
  &.align-center {
    margin-left:auto;
    margin-right:auto;
  }
}

// Text Size Size
@each $key,$value in map-get($text-sizes,'mobile') {
  .text--#{$key} {
    @include u-set-text-size('mobile',$key);
  }
}

@media #{$medium-up} {
  @each $key,$value in map-get($text-sizes,'tablet') {
    .text--#{$key} {
      @include u-set-text-size('tablet',$key);
    }  
  }
}
@media #{$large-up} {
  @each $key,$value in map-get($text-sizes,'desktop') {
    .text--#{$key} {
      @include u-set-text-size('desktop',$key);
    }  
  }
  
}

.icon-block__content,
.text-content {
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    a {
      @include t-link-base;
    }
  }
  h1,.h1 {
    line-height: 1.25;
  }
  h2,.h2 {
    margin-top: spacing('lg');
    margin-bottom:spacing('md');
  }
  .text-content__title {
    text-align: center;
    margin-top:0;
    margin-bottom: spacing('lg');
  }
  .text-content__title--has-subtitle {
    margin-bottom: spacing('md');
  }
  .text-content__subtitle {
    text-align: center;
    margin-bottom: spacing('lg');
  }
  ol {
    margin-bottom:spacing('md');
    > li + li {
      margin-top: spacing('xs');
    }
  }
  ul {
    list-style: none;
    margin-bottom: spacing('md');
    a {
      @include t-link-base;
    }
    li {
      position: relative;
      padding-left: spacing('md');
      line-height:1.875;
      &::before {
        content:'»';
        position: absolute;
        line-height: 1.4;
        // margin-right: spacing('md');
        top:0;
        left:0;
      }
      ul {
        margin-top: spacing('md');
      }
    }
    > li + li {
      margin-top: spacing('xs');
    }
  }
  ol {
    a {
      @include t-link-base;
    }
    li {
      line-height:1.875;
    }
  }
  > *:first-child {
    margin-top:0;
  }
  > *:last-child {
    margin-bottom:0;
  }
  
}
.sample-card {
  text-align: center;
}
.sample-card__chip {
  width:40px;
  height:40px;
  border:solid 1px get-color('gray--dark');
  margin-left: auto;
  margin-right: auto;
  margin-bottom: spacing('sm');
  box-shadow: 0 1px 5px rgba(0,0,0,0.5);
}
/* stylelint-enable selector-max-universal,selector-max-compound-selectors,  declaration-no-important */