.stack {
  > * {
    margin-bottom:0 !important;
  }
  > * + * {
    margin-top: spacing('sm');
  }
  > .align-center {
    align-self: center;
  }
}

.stack--h-center {
  display:flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width:100%;
  > *{
    max-width:100%;
    flex:1 1 auto;
  }
  
}

@each $key,$value in $space-scale {
  .stack--spacing-#{$key} {
    > * + * {
      margin-top: spacing($key, $space-scale-mobile);
    }
  }
}


@media #{$small-only} {
  .stack--sm--h-center {
    display:flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width:100%;
    > *{
      max-width:100%;
      flex:1 1 auto;
    }
  }
}


@media #{$medium-up} {
  .stack--md--h-flex-start {
    display: block;
  }
  @each $key,$value in $space-scale {
    .stack--spacing-#{$key},
    .stack--md--spacing-#{$key} {
      > * + * {
        margin-top: spacing($key);
      }
    }
  }
}

@media #{$large-up} {
  @each $key,$value in $space-scale {
    .stack--lg--spacing-#{$key} {
      > * + * {
        margin-top: spacing($key);
      }
    }
  }
}