// Text Size Size
@each $key,$value in map-get($text-sizes,'mobile') {
  .text--#{$key} {
    @include u-set-text-size('mobile',$key);
    
  }
}

@media #{$medium-up} {
  @each $key,$value in map-get($text-sizes,'desktop') {
    .text--#{$key} {
      @include u-set-text-size('desktop',$key);
    }  
  }
}

.lineheight--condensed {
  line-height: 1.15;
}
.lineheight--tight {
  line-height:1.3333333333;
}

