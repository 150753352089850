// Project mixins

// !Utilities
// These do one single thing
@mixin u-set-typography($typeface-name: 'body', $style-name: 'regular') {
  $this-typeface: map-get($font-styles, $typeface-name);
  $this-style: map-get($this-typeface, $style-name);
  $this-fontfamily: map-get($this-style, 'font-family');
  $this-fontweight: map-get($this-style, 'font-weight');

  font-family: $this-fontfamily;
  font-weight: $this-fontweight;
}

@mixin u-typography-scale($size: 1) {
  font-size: $font-base-size  * pow($font-scale-ratio, $size);
}

@mixin u-set-text-size($device, $size) {
  $this-device: map-get($text-sizes,$device);
  $this-size: map-get($this-device,$size);
  $this-ratio: $font-scale-ratio;
  @if($device == 'mobile') {
    $this-ratio: $font-scale-ratio--small;
  } 
  
  font-size: $font-base-size  * pow($this-ratio, $this-size);
}

// !Theming mixins
// pure appearance (typographic/cosmetic) styles

@mixin t-link-base {
  display: inline;
  color: $color-link!important;
  font-weight:bold;
  text-decoration: none;
  transition: opacity .3s ease;
  &:hover {
    color: $color-link!important;
    opacity: 0.5;
    text-decoration: none;
  }
}

@mixin clearDefaultButtonStyles {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin t-focused {
}

@mixin u-remove-button-styling {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  /* show a hand cursor on hover; some argue that we
  should keep the default arrow cursor for buttons */
  cursor: pointer;
}

@mixin u-grid-container($w) {
  /* stylelint-disable-next-line meowtec/no-px */
  max-width: $w * 1px;
}

@mixin u-create-grid-row($size: null) {
  $klass: set-name(row, $size);

  .#{$klass} {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    &--nested {
      /* stylelint-disable-next-line meowtec/no-px */
      margin-left: ($column-gutter / 2) * -1px;
      /* stylelint-disable-next-line meowtec/no-px */
      margin-right: ($column-gutter / 2) * -1px;
    }

    &--nowrap {
      flex-wrap: nowrap;
    }

    &--flipped {
      flex-direction: row-reverse;
    }
    &--space-around {
      justify-content: space-around;
    }
    &--space-between {
      justify-content: space-between;
    }
    &--flex-start {
      justify-content: flex-start;
    }
    &--flex-end {
      justify-content: flex-end;
    }

    &--valign-top {
      align-items: flex-start;
    }
    &--valign-center {
      align-items: center;
    }
    &--valign-bottom {
      align-items: flex-end;
    }
    &--valign-baseline {
      align-items: baseline;
    }
  }
}

@mixin u-column($column-size) {
  flex-basis: grid-calc($column-size, $total-columns);
  max-width: grid-calc($column-size, $total-columns);
} 
@mixin u-create-grid-columns($size: null) {
  $klass: set-name(column, $size);

  @for $i from 1 through $total-columns {
    .#{$klass}--#{$i} {
      flex: 1 1 grid-calc($i, $total-columns);
      max-width: grid-calc($i, $total-columns);
    }
    .#{$klass}--p-push-#{$i} {
      margin-left: grid-calc($i, $total-columns) * 1;
    }
    .#{$klass}--p-pull-#{$i} {
      margin-right: grid-calc($i, $total-columns) * 1;
    }

    .#{$klass}--push-#{$i} {
      margin-right: grid-calc($i, $total-columns) * -1;
    }
    .#{$klass}--pull-#{$i} {
      margin-left: grid-calc($i, $total-columns) * -1;
    }
  }
}

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin header-styles($font-size, $line-height, $margin-bottom) {
  font-size: rem-calc($font-size);
  @if $line-height {
    line-height: lineheight-calc($line-height, $font-size);
  }
  margin: 0 0 em-calc($margin-bottom, $font-size) 0;
}
