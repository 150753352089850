.stack-inline {
  display: flex;
  text-align: center;
  flex-direction:column;
  align-items: center;

  > * + * {
    margin-top: spacing('sm');
  }
}

@each $key,$value in $space-scale {
  .stack-inline--spacing-#{$key} {
    > * + * {
      margin-top: spacing($key);
    }
  }
}

@media #{$medium-up} {
  .stack-inline {
    flex-direction:row;
    > * + * {
      margin-top: 0;
      margin-left: spacing('sm');
    }
  }
  .stack-inline--h-space-around {
    justify-content: space-around;
  }
  .stack-inline--h-center,
  .stack-inline--md--h-center {
    justify-content: center;
  }
  .stack-inline--h-flexend,
  .stack-inline--md--h-flexend {
    justify-content: flex-end;
  }

  @each $key,$value in $space-scale {
    .stack-inline--spacing-#{$key},
    .stack-inline--md--spacing-#{$key} {
      > * + * {
        margin-top:0;
        margin-left: spacing($key);
      }
    }
  }

}

@media #{$large-up} {
  .stack-inline--lg--h-center {
    justify-content: center;
  }

  .stack-inline--lg--h-flexend {
    justify-content: flex-end;
  }
  @each $key,$value in $space-scale {
    .stack-inline--lg--spacing-#{$key} {
      > * + * {
        margin-left: spacing($key);
      }
    }
  }
}