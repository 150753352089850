
$directions: ('t':top,'r':'right','b': 'bottom', 'l': 'left');




@each $key,$value  in $space-scale {
  .mb--none {
    margin-bottom:0;
  }
  .py--#{$key} {
    padding-top: spacing($key,$space-scale-mobile);
    padding-bottom: spacing($key,$space-scale-mobile);
  }
  @media #{$medium-up} {
    .py--#{$key} {
      padding-top: spacing($key,$space-scale-tablet);
      padding-bottom: spacing($key,$space-scale-tablet);
    }  
  }
  @media #{$large-up} {
    .py--#{$key} {
      padding-top: spacing($key);
      padding-bottom: spacing($key);
    }  
  }
  @each $abb,$dir in $directions {
    .m#{$abb}--#{$key} {
      margin-#{$dir}: spacing($key,$space-scale-mobile);
    }
    .p#{$abb}--#{$key} {
      padding-#{$dir}: spacing($key,$space-scale-mobile);
    }
    @media #{$medium-up} {
      .m#{$abb}--#{$key} {
        margin-#{$dir}: spacing($key,$space-scale-tablet);
      }
      .p#{$abb}--#{$key} {
        padding-#{$dir}: spacing($key,$space-scale-tablet);
      }
    }
    @media #{$large-up} {
      .m#{$abb}--#{$key} {
        margin-#{$dir}: spacing($key);
      }
      .p#{$abb}--#{$key} {
        padding-#{$dir}: spacing($key);
      }
    }
  } 
}