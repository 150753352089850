.footer {
  margin-top:50px;
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  label {
    margin-bottom: 0;
  }
}
.footer-section-state {
  display: none;
}

.footer__links {
  padding-top: spacing('xxl',$space-scale-mobile);
  padding-bottom: spacing('xl',$space-scale-mobile);
  background: get-color('gray--darker');
  color:get-color('white');
  @include u-set-text-size('mobile','sm');
  .container {
    // margin-bottom: spacing('md',$space-scale-mobile) * -1;
  }
}


.footer-link__block {
  flex-basis: 100%;
  flex-shrink: 0;
  padding: 0 spacing('sm');
  margin-bottom: spacing('md');
  text-align: center;
  &:last-child {
    margin-bottom: 0;
  }
}
.footer-link__header {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: spacing('xs');
  font-size: 15px!important;
  color: white!important;
  font-weight:700;
}

.footer-link__lists {
  > * + * {
    padding-top:spacing('xxs');  
  }
}

.footer-link__item {
  font-size: 14.5454px;
  line-height: 1.500006875em;
  margin-bottom: 0;
}

.footer-link__link {
  color: get-color('white');
  &:hover {
    color: $color-link;
  }
}
.footer-link__contact {
  font-size: 14.5454px;
  line-height: 1.500006875em;
  a {
    display: block;
  }
}


.footer__copyright {
  padding: 20px 0;
  background: get-color('gray--text');
  font-size: rem-calc(12px);
  color: get-color('white');
  .row-lg {
    margin-bottom: spacing('md') * -1;
    > .column {
      margin-bottom: spacing('md');
    }
  }
}
.footer__logo {
  display: inline-block;
  margin-left:auto;
  margin-right:auto;
  img {
    margin-top: -.25em;
  }
}

.copyright__details {
  text-align: center;
  margin-bottom:spacing('sm') * -1;
  > * {
    margin-bottom: spacing('sm');
  }
  p {
    font-size: 12px;
    margin-bottom:0;
  }

}
.copyright__link {
  @include u-set-text-size('mobile','sm');
  font-weight: 600;
  text-transform: uppercase;
  justify-content: center;
  a {
    color: get-color('white');
    &:hover {
      opacity: .5;
    }
  }
}
@media #{$small-only} {
  .footer-section-state:checked + .footer-section .footer-link__lists{
    position: static;
    visibility: visible;
    z-index: 1;
    transform: none;
    transition: transform 300ms ease;
  }
  .footer-section-state:checked + .footer-section .footer-link__header::after{ 
    transform:rotate(45deg);
  }
  .footer-section {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-bottom:solid 1px rgba(255,255,255,.5);
    .footer-link__header {
      margin-bottom:0;
    }
  }
  .footer-link__block {
    padding:0;
    margin:0;
    label {
      display: block;
      cursor: pointer;
      padding:spacing('sm') spacing('sm');
    }
  }
  .footer-link__header {
    position: relative;
    &::after {
      content:'+';
      float:right;
      display:block;
      transition: transform .3s ease;
      backface-visibility: hidden;
    }
  }
  .footer-link__header--contact {
    text-align: center;
    &::after {
      content:none;
    }
  }
  .footer-link__lists {
    margin: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: spacing('md');
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    visibility: hidden;
    z-index: -1;
    transform: translateY(-100px);
    // text-align: left;
    // padding-left:22px;
  }
}
@media #{$medium-up} {
  .footer__links {
    padding-top: spacing('xxl');
    padding-bottom: spacing('xl');
    .container {
      margin-bottom: spacing('md') * -1;
    }
  }
  .footer__logo {
    flex:0 0 200px;
  }
  .footer-link__block {
    flex-basis: 50%;
    text-align: left;
    .container {
      margin-bottom: spacing('md');
    }
  }
  .footer-link__header {
    text-align: left;
  }
  .copyright__details {
    text-align: left;
    display: flex;
    align-items: flex-start;
    > * + * {
      margin-left: spacing('lg');
    }
  }
  .footer__links__a {
    display: flex;
    flex-wrap:wrap;
  }
}

@media #{$xlarge-up} {
  .footer-link__block {
    flex:1 1 25%;
    max-width:25%;
  } 

  .copyright__link {
    justify-content: flex-end;
  }
}