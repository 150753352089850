/* stylelint-disable selector-max-universal, selector-max-compound-selectors, selector-max-type */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @include u-set-typography;
  @include font-smoothing;
  
  color: $color-text;
  display:flex;
  flex-direction: column;
  line-height: 1.5;
  
}

img {
  display: inline-block;
  border:0;
  max-width: 100%;
  height: auto;
}
a {
  // @include t-link-base;
  color:inherit;
  text-decoration: none;
}

p {
  line-height: $base-line-height--small;
  margin-bottom: spacing('md');
  a {
    @include t-link-base;
  }
  //1.6666666667; //30/16
}

address {
  font-style: normal;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: spacing('md');
  line-height: 1.15;
}

// Font Family + Font Weight
h1, .h1 {
  @include u-set-typography('heading','bold');
  @include u-set-text-size('mobile','xxxl');
  
}
h2, .h2 {
  @include u-set-typography('heading','bold');
  @include u-set-text-size('mobile','xxl');
}
h3, .h3 {
  @include u-set-typography('heading','bold');
  @include u-set-text-size('mobile','md');
  line-height:1.4444;
  margin-bottom: spacing('xs');
}
h4,.h4 {
  @include u-set-typography('heading','regular');
  @include u-set-text-size('mobile','xl');
  line-height: 1.4;
}
h5, .h5 {
  @include u-set-typography('heading','semibold');
  @include u-set-text-size('mobile','base');
  line-height: 1.5;
}
h6, .h6 {
  @include u-set-typography('heading','bold');
  @include u-set-text-size('mobile','sm');
}
small,.small {
  @include u-set-text-size('mobile','sm');
}
blockquote {
  @include u-set-typography('body','light');
  @include u-set-text-size('mobile','xxl');
}
.main-wrapper{
  ul:not(.nav) {
    list-style: none;
    margin-bottom: spacing('md');
    overflow: auto;
    li {
      position: relative;
      padding-left: spacing('md');
      &::before {
        content:'»';
        position: absolute;
        line-height: 1.4;
        top:0;
        left:0;
      }
    }
    > li + li {
      margin-top: spacing('xs');
    }
  }
}
/* stylelint-enable selector-max-universal, selector-max-type */


/* stylelint-disable selector-max-type */

@media #{$medium-up} {
  h1, .h1 {
    @include u-set-text-size('desktop','xxl');
  }
  
  h2, .h2 {
    @include u-set-text-size('desktop','xl');
  }
  h3, .h3 {
    @include u-set-text-size('desktop','md');
  }
  h4, .h4 {
    @include u-set-text-size('desktop','lg');
  }
  p {
    line-height: $base-line-height;
  }
}

@media #{$large-up} {
}
/* stylelint-enable selector-max-type */
