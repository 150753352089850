/* stylelint-disable color-no-hex */
$colors: (
  'turquoise': #00A5B6,
  'indigo': #3E4F7D,
  'gray--text': #333333,
  'gray--dark': #4E4E52,
  'gray': #A6A2A3,
  'gray--light': #F3F2F1,
  'turquoise--light': #F0FCFD,
  'orange--dark': #F05123,
  'yellow': #FBB040,
  'green': #6AA744,
  'aegean': #00617F,
  'purple': #6E416C,
  'red': #9C2E33,
  'periwinkle': #6699CC,
  'lime': #CCCC33,
  'orange': #F58220,
  'blue': #0064A4,
  'pink': #CC6699,
  'black': #000000,
  'black--alpha50': rgba(0,0,0,0.5),
  'gray--medium': #EBEAE8,
  'gray--darker': #101010,
  'turquoise--alpha50':rgba(0, 165, 182, .5),
  'turquoise--alpha0':rgba(0, 165, 182, 0),
  'white': #ffffff,
  'white--alpha70': rgba(255,255,255,0.7),
  'white--alpha50': rgba(255,255,255,0.5),
  'white--alpha20': rgba(255,255,255,0.2),
  'white--alpha0': rgba(255,255,255,0),
  'gray--dark--alpha80': rgba(78, 78, 82, .8),
);

$color-text: get-color('gray--text');
$color-link: get-color('turquoise');
$color-bg: get-color('white');

$sectionColors: (
  'inetco-insight': get-color('turquoise'),
  'insight-core-technology': get-color('indigo'),
  'insight-services': get-color('green'),
  'inetco-solutions-industry': get-color('purple'),
  'inetco-solutions-role': get-color('yellow'),
  'inetco-solutions-channel': get-color('orange--dark'),
  'case-studies': get-color('turquoise'),
  'dashboard': get-color('indigo'),
  'datasheets': get-color('green'),
  'infographics': get-color('purple'),
  'product-videos': get-color('yellow'),
  'whitepapers': get-color('orange--dark'),
  'webinars': get-color('aegean'),
  'inetco-about': get-color('turquoise'),
  'inetco-team': get-color('indigo'),
  'inetco-partners': get-color('purple'),
  'inetco-careers': get-color('green'),
  'inetco-blog': get-color('orange--dark'),
  'inetco-support': get-color('yellow'),
  'inetco-contact': get-color('aegean')
);

$gradients: (
  'footer': linear-gradient(90deg, #00A5B6 0%, #3E4F7D 49%, #6E416C 100%),
  'blog-subscribe': linear-gradient(145deg, #00A5B6 0%, #3E4F7D 49%, #6E416C 100%)
);
/* stylelint-enable color-no-hex */
