@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

$font-family--opensans: 'Open Sans', sans-serif;
$font-scale-ratio: 1.125;
$font-scale-ratio--small: 1.1;
$font-base-size: 16px;
$base-font: 16;
$base-line-height--small: 1.4;
$base-line-height: 1.6666666667;

// A map containing the basic typography for all the site
// The 2nd level of the map (body and heading) corresponds to the main typefaces.
// The 3rd level of the map corresponds to font property combinations that frequently go together. Any arbitrary word goes as a group name. The properties are usually font-size and line-height but can be also any other. Please avoid including color and other non typography properties here, though.
// Usage: @include u-set-typography($typeface, $style-name);

/* stylelint-disable indentation */
$font-styles: (
  'heading': (
    'regular': (
      'font-family': $font-family--opensans,
      'font-weight': 400,
    ),
    'semibold': (
      'font-family': $font-family--opensans,
      'font-weight': 600,
    ),
    'bold': (
      'font-family': $font-family--opensans,
      'font-weight': 700,
    ),
  ),
  'body': (
    'light': (
      'font-family': $font-family--opensans,
      'font-weight': 300,
    ),
    'regular': (
      'font-family': $font-family--opensans,
      'font-weight': 400,
    ),
    'semibold': (
      'font-family': $font-family--opensans,
      'font-weight': 600,
    ),
    'bold': (
      'font-family': $font-family--opensans,
      'font-weight': 700,
    ),
  ),
);

$text-sizes: (
  'mobile' : (
    'xs' : -2,
    'sm' : -1,
    'base': 0,
    'md': 1,
    'lg' : 2,
    'xl' : 3,
    'xxl' : 4,
    'xxxl': 5,
    'xxxxl': 6,
  ),
  'tablet' : (
    'xl' : 4,
    'xxl': 5,
    'xxxl': 6,
    'xxxxl': 8
  ),
  'desktop' : (
    'base': 0,
    'md': 1,
    'lg' : 2,
    'xl' : 4,
    'xxl': 6,
    'xxxl': 8,
    'xxxxl': 10
  )
)

/* stylelint-enable indentation */
