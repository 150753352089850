// Button
button,
.button {
  @include clearDefaultButtonStyles;
  outline:transparent;

  font-weight: bold;
  text-transform: uppercase;
  border-radius: 2px;
  // padding: 0.875rem spacing('md');
  padding: spacing('xs') spacing('md');
  display: inline-block;
  margin: 0;
  letter-spacing: 0.01em;
  line-height: 1.5;
  text-align: center;
  border: solid 2px get-color('turquoise')!important;
  transition: border-color .3s ease, background-color .3s ease;
  text-decoration: none!important;
  &:hover {
    opacity:1;
    text-decoration: none!important;
  }
}

.button--condensed {
  padding-top: spacing('xs');
  padding-bottom: spacing('xs');
  border-width: 1px;

}
.button--primary {
  background: get-color('turquoise')!important;
  border-color: transparent!important;
  color: get-color('white')!important;
  &:hover {
    background: get-color('turquoise--alpha50')!important;
    border-color: transparent!important;
    color: get-color('white')!important;
  }
}

.gradient--footer .button--primary,
.panel--page-header .button--primary,
.button--primary-light {
  background: get-color('white')!important;
  color: get-color('turquoise')!important;
  border-color: get-color('white')!important;
  &:hover {
    border-color: transparent!important;
    background: get-color('white--alpha70')!important;
  }
}

.button--secondary {
  color: get-color('turquoise')!important;
  background: get-color('turquoise--alpha0')!important;
  &:hover {
    border-color: get-color('turquoise--alpha0')!important;
    background: get-color('turquoise--alpha50')!important;
  }
}

.bg--gray--light .button--secondary,
.gradient--footer .button--secondary,
.panel--page-header .button--secondary,
.button--secondary-light {
  color: get-color('white')!important;
  border-color: get-color('white')!important;
  background: get-color('white--alpha0')!important;
  &:hover {
    border-color: transparent!important;
    background: get-color('white--alpha20')!important
  }
}


.button--icon {
  align-items: center;
  border:none;
  display: flex;
  padding:0 spacing('xs');
} 

.button--demo {
  @include u-set-text-size('mobile','sm');
  background: get-color('turquoise')!important;
  border-color: transparent!important;
  color: get-color('white')!important;
  padding: spacing('xs') spacing('xs');
  &:hover {
    background: get-color('turquoise--alpha50')!important;
  } 
}

@media #{$medium-up} {
  .button--primary {
    .medium--on-dark & {
      background: get-color('white')!important;
      color: get-color('turquoise')!important;
      border-color: get-color('white')!important;
      &:hover {
        background: get-color('white--alpha70')!important;
        border-color: transparent!important;
      }
    }
  }
  .button--secondary {
    .medium--on-dark & {
      color: get-color('white')!important;
      border-color: get-color('white')!important;
      background: get-color('white--alpha0')!important;
      &:hover {
        background: get-color('white--alpha20')!important;
        border-color: transparent!important;
      }
    }
  }

}

@media #{$large-up} {
  .button--demo {
    padding: spacing('sm') spacing('sm');
    @include u-set-text-size('mobile','xs'); 
  }
}
@media #{$xlarge-up} {
  .button--demo {
    // padding: 0.875rem spacing('md');
    padding: spacing('sm') spacing('md');
    @include u-set-text-size('mobile','base'); 
  }
}
