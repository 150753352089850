// Links
.link {
  @include t-link-base;
  text-transform: uppercase;
  // font-weight:600;
}

.link-button {
  @include t-link-base;
}

.link--arrow {
  &::after {
    content: '»';
    display: inline-block;
    font-weight:600;
    font-size: 1.5em;
    // line-height: 1;
    padding-left:5px;
    transition: transform .3s ease;
    position: relative;
    top:1px;
  }
  &:hover {
    &::after {
      transform:translateX(3px);
    }
  }
}
.link--back {
  &::before {
    content: '«';
    display: inline-block;
    font-weight:600;
    font-size: 1.5em;
    // line-height: 1;
    padding-right:5px;
    transition: transform .3s ease;
    position: relative;
    top:1px;
  }
  &:hover {
    &::before {
      transform:translateX(-3px);
    }
  }
}

.link--down,
.link--download {
  border:none;
  padding:0;
  &::after {
    display: inline-block;
    content: '»';
    font-weight:600;
    font-size: 1.5em;
    line-height: 1;
    padding-bottom:10px;
    margin-top: -0.15em;
    margin-left:.3em;
    transition: transform .3s ease;
    transform: rotate(90deg);
    position: relative;
    top:-1px;
  }
  &:hover {
    &::after {
      transform: rotate(90deg) translateX(3px);
    }
  }
}
.link--up {
  border:none;
  padding:0;
  &::after {
    display: inline-block;
    content: '»';
    font-weight:600;
    font-size: 1.5em;
    line-height: 1;
    padding-bottom:10px;
    margin-top: -0.15em;
    margin-left:.3em;
    transition: transform .3s ease;
    transform: rotate(-90deg);
    position: relative;
    top:-1px;
  }
  &:hover {
    &::after {
      transform: rotate(-90deg) translateX(3px);
    }
  }
}