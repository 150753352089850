/* stylelint-disable */
.nav-skip {
  left: -100vw;
  position: absolute;
}

.navbar {
  padding: spacing('sm') 0;
  position: relative;
  z-index:99;
  .js--nav-sticky & {
    width:100%;
    background: get-color('white');
    position:fixed;
    z-index:99;
    top:0;
    box-shadow: 1px -2px 12px rgba(0,0,0,.18);
  }
}

.navbar__logo {
  padding-right: spacing('md');
  max-width:150px;
  > a {
    display: block;
    transition: transform .3s ease;
    &:hover {
      transform: translateX(1px);
      
      
    }
  }
}
.navbar__logo--mobile {
  padding-right:0;
  margin-bottom: spacing('md');
}

.navbar__nav {
  display:none;
  .js--nav-open & {
    background:#fff;
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index:55;
  }
}

.navbar__action {
  display: none;
}
.nav__list__action {
  margin-top:spacing('md');
}

.nav__toggler {
  background: none;
  border: none;
  display: block;
  padding: 0;
  position: relative;
  // right: 10px;
  // top: 10px;
  z-index: 56;
}
.nav__toggler__content {
  padding: 10px 2px;
}
.nav__toggler__icon {
  $dimension: 24px;

  height: 17px;
  position: relative;
  width: $dimension;

  .nav__toggler__icon__lines,
  &::before,
  &::after {
    background-color: get-color('gray');
    border-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    transform-origin: 50% 50%;
    transition: transform 0.2s ease 0s;
    width: $dimension;
  }

  &::before {
    top: 0;
    transform: translateY(0) rotate(0deg);
  }

  &::after {
    top: 14px;
    transform: translateY(0) rotate(0deg);
  }

  .nav__toggler__icon__lines {
    top: 7px;
    transform: scaleX(1);
    transition: transform 0.15s ease 0s;
  }

  .js--nav-open & {
    .nav__toggler__icon__lines,
    &::before,
    &::after {
      background-color: get-color('gray');
    }
    .nav__toggler__icon__lines {
      transform: scaleX(0);
    }
    &::before {
      top: 4px;
      transform: translateY(3px) rotate(45deg);
    }
    &::after {
      top: 24px;
      transform: translateY(-17px) rotate(-45deg);
    }
  }
}

.nav__list {
  display: block;
  list-style: none;
  padding: 0;
}

.nav__item{
  // flex: 0 0 auto;
  &.nav__item_current-ancestor,
  &.nav__item_current-parent {
    > .nav__link {
      color: $color-link;
    }
  }
  &.nav__item_current {
    > .nav__link {
      color: $color-link;
    }
  }
}

.nav__link {
  display: block;
  color: $color-text;
  text-align: center;
  font-weight:700;
  span {
    transition: color .3s ease;
  }
  &:hover {
    opacity:1;
    color: $color-link;
  }
}

.nav__icon {
  display: block;
  height:70px;
  margin-bottom:spacing('xs');
}


.nav__list--primary {
  display: block;
  width:100%;
  .nav__link {
    text-transform: uppercase;
  }
  .nav__item_level-1 {
    &.js--open {
      > .nav__link {
        color: $color-link;
        
      }  
      .submenu__panel {
        opacity: 1;
        visibility: visible;
        display: block;
        .container {
          transform: translateY(0);
          opacity: 1;
        }
      }    
    }
    > .nav__link {
      @include u-set-text-size('mobile','md');
    }
  }
  .submenu__panel {
    padding: spacing('sm') 0;
    opacity: 0;
    visibility: hidden;
    display: none;
    z-index:2;
  }
  .nav__submenu {
    margin:0 auto;
  }
}
.nav__menu_level-2 {
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
  &.nav__submenu {
    @each $sectionName, $hex in $sectionColors {
      .color--#{$sectionName} .nav__icon,
      .nav__item_has-submenu .color--#{$sectionName} span {
        color: $hex;
      }
    }
  }
  
  > .nav__item_level-2 > .nav__link {
    margin-bottom: spacing('xs');
  }
}

.nav__menu_level-3 {
  display: none;
  > .nav__subitem + .nav__subitem {
    padding-top: spacing('xxs');
  }
}

.nav__list--util {
  padding: spacing('xxs') 0;
  .nav__link {
    color: get-color('white');
    @include u-set-text-size('mobile','sm');
    transition: color .3s ease;
    &:hover {
      opacity:1;
      color: $color-link;
    }
  }
}


@media #{$medium-up} {
  .nav__list--util {
    display: flex;
    .nav__item {
      padding: 0 spacing('xs');
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

@media #{$large-up} {
  .nav__toggler {
    display: none;
  }
  .navbar {
    padding-bottom:0;
  }

  .navbar__logo {
    padding-right: spacing('sm');
    padding-bottom: spacing('sm');
    flex: 0 1 auto;
    max-width: 190px;
    
  }

  .navbar__nav {
    display: flex;
    flex: 1 0 auto;
    padding-right: spacing('sm');
    align-self: stretch;
    justify-content: center;
  }

  .nav__item {
    flex: 0 0 auto;
    padding: 0 spacing('xs');
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .nav__list--primary {
    display: flex;
    align-self: stretch;
    width:auto;
    .nav__item_level-1 {
      > .nav__link {
        @include u-set-text-size('mobile','base');
        padding-bottom: spacing('sm');
      }
    }
    .nav__item_level-1.nav__item_has-submenu:hover{
        > .nav__link {
          color: $color-link;
        }
    }
    .nav__item {
      display: flex;
      &:hover {
        .submenu__panel {
          opacity: 1;
          visibility: visible;
          .container {
            transform: translateY(0);
            opacity: 1;
          }
        }    
      }
    }
    .nav__link {
      display: flex;
      align-items: center;
      
    }
    .submenu__panel {
      background: get-color('gray--light');
      display: block;
      left:0;
      padding: spacing('md') 0 spacing('md') 0;
      position: absolute;
      top:100%;
      width:100%;
      box-shadow: 1px 3px 12px rgba(0,0,0,.12);
      transition: opacity .3s ease, visibility .3s ease;
      .container {
        transform: translateY(-10px);
        opacity: 0;
        transition: opacity .3s ease .2s, transform .3s ease .2s;
      }
    }
  }

  .nav__menu_level-2 {
    display: flex;
    width:100%;
    justify-content: center;
    align-items: flex-start;
    flex-wrap:nowrap;
    .nav__link {
      font-weight:600;
      margin-bottom: spacing('xxs');
    }

    .nav__item {
      flex:1 0 auto;
    }

    .nav__item,
    .nav__link,
    .nav__link span {
      padding:0;
      display: block;
    }

    > .nav__subitem + .nav__subitem {
      margin-top:0;
    }

    > .nav__item > .nav__link {
      text-transform: none;
      margin-bottom: spacing('sm');
      font-size: em-calc(18px,16px);
      > .nav__link__text {
        font-weight: bold;
      }
    }
    > .nav__item_has-submenu {
      text-align: left;
      flex: 0 1 auto;
      padding:0 spacing('xl');

      > .nav__link > .nav__link__text {
        font-weight:bold;
      }
      .nav__link span {
        text-align: left;
      }
      
    }

  }

  .nav__menu_level-3 {
    display: block;
    text-align: left;
    .nav__item {
      padding:0;
    }
    .nav__link {
      text-align: left;
      padding:0;
      text-transform: none;    
      font-size: em-calc(15px,16px);
    }
  }

  
  
  .navbar__action {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: spacing('sm');
  }
  .action__demo {
    margin-left: spacing('xs');
  }
}

@media #{$xlarge-up} {
  .navbar__logo {
    max-width:none;
  }
  .navbar__logo,
  .navbar__nav {
    padding-right: spacing('md');
  }

  .nav__item {
    padding: 0 spacing('sm');
  }

  .action__demo {
    margin-left: spacing('sm');
  }
}
@media #{$xxlarge-up} {
  .navbar__logo,
  .navbar__nav {
    padding-right: spacing('md');
  }
  .nav__item {
    padding: 0 1.125rem;
  }
  .nav__menu_level-3 {
    .nav__item {
      text-align: left;
    }
  }
}
/* stylelint-enable */