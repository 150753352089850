.container-new {
  @include u-grid-container($grid-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: ($column-gutter / 2) * 1px;
  padding-right: ($column-gutter / 2) * 1px;
  width: 100%
}

.container--full-width {
  padding-left: ($column-gutter / 2) * 1px;
  padding-right: ($column-gutter / 2) * 1px;
}
.row-no-collapse {
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  .column {
    padding-left: ($column-gutter / 2) * 1px;
    padding-right: ($column-gutter / 2) * 1px;
  }
}
.row {
  margin-left: 0;
  margin-right: 0;
}
@include u-create-grid-row(no-collapse);

.column {
  padding-bottom: ($column-gutter / 2) * 1px;
  &:last-child {
    padding-bottom: 0;
  }
}

.column--auto {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.column--no-flexgrow {
  flex-grow: 0;
}
.column--no-padding {
  padding-left: 0;
  padding-right: 0;
}

.row {
  width:100%;
}

@media #{$medium-up} {
  .row {
    display: flex;
    justify-content: center;
    &--reversed {
      flex-direction: row-reverse;
    }
  }
  @include u-create-grid-row();
  @include u-create-grid-columns();
  .column {
    padding-left: ($column-gutter / 2) * 1px;
    padding-right: ($column-gutter / 2) * 1px;
    padding-bottom: 0;
    > .row {
      margin-left: ($column-gutter / 2) * -1px;
      margin-right: ($column-gutter / 2) * -1px;
    }
  }
}

@media #{$large-up} {
  @include u-create-grid-row(lg);
  @include u-create-grid-columns(lg);
}
@media #{$xlarge-up} {
  @include u-create-grid-row(xl);
  @include u-create-grid-columns(xl);
}
