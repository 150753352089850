.container--full-width {
  width:100%;
}
.container--wide {
  max-width: 1960px; //1920+20+20
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}
.page-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height:100vh;
}

.c-content-area {
  flex: 1 1 auto;
  // background:black;
}

.main-wrapper {
  min-height: calc(100vh - 580px);
}


@media #{$medium-up} {
}

@media #{$large-up} {
}

@media #{$xlarge-up} {
  .container--wide {
    max-width: 2000px; //1920+20+20
    padding: 0 40px;
  }
}
