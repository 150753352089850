.inline {
  display:flex;
  > * + * {
    margin-left: spacing('sm');
  }
}

.inline--wrap {
  flex-wrap: wrap;
  margin-bottom:spacing('sm') * -1;
  > * {
    margin-right: spacing('sm');
    margin-bottom:spacing('sm');
  }
  > *:last-child {
    margin-right:0;
  }
  > * + * {
    margin-left:0; 
  }
}
.inline--v-center {
  align-items: center;
}
.inline--h-center {
  justify-content: center;
}
.inline--h-flexend {
  justify-content: flex-end;
}

@each $key,$value in $space-scale {
  .inline--spacing-#{$key} {
    > * + * {
      margin-left: spacing($key);
    }
  }
}

@media #{$xsmall-only} {
  .inline--xs--v-center {
    align-items: center;
  }
  .inline--xs--h-center {
    justify-content: center;
  }
  .inline--xs--h-flexend {
    justify-content: flex-end;
  }

  @each $key,$value in $space-scale {
    .inline--xs--spacing-#{$key} {
      > * + * {
        margin-left: spacing($key);
      }
    }
  }
}


@media #{$medium-up} {
  .inline--md--v-center {
    align-items: center;
  }
  .inline--md--h-center {
    justify-content: center;
  }
  .inline--md--h-flexend {
    justify-content: flex-end;
  }

  @each $key,$value in $space-scale {
    .inline--md--spacing-#{$key} {
      > * + * {
        margin-left: spacing($key);
      }
    }
  }
}

@media #{$large-up} {
  .inline--lg--v-center {
    align-items: center;
  }
  .inline--lg--h-center {
    justify-content: center;
  }
  .inline--lg--h-flexend {
    justify-content: flex-end;
  }

  @each $key,$value in $space-scale {
    .inline--lg--spacing-#{$key} {
      > * + * {
        margin-left: spacing($key);
      }
    }
  }
}