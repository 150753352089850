.u-svg-icon-about-inetco-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-advisory-services-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-alerts-engine-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-apis-data-forwarding-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-atm-channel-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-b-size {
	width: 32px;
	height: 32px;
}

.u-svg-icon-c-level-role-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-card-issuer-solutions-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-card-operations-role-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-case-management-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-case-studies-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-channel-managers-analysts-role-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-cloud-hosted-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-contact-inetco-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-credit-union-solutions-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-dashboard-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-dashboard-examples-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-datasheets-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-debit-credit-cards-channel-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-download-size {
	width: 28.8px;
	height: 20.7px;
}

.u-svg-icon-fb-size {
	width: 32px;
	height: 32px;
}

.u-svg-icon-iads-solutions-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-implementation-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-about-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-blog-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-bullet-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-careers-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-contact-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-future-real-time-business-intel-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-future-real-time-fraud-detection-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-future-real-time-monitoring-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-insight-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-partners-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-payment-fraud-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-solutions-channel-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-solutions-industry-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-solutions-role-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-support-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-inetco-team-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-infographics-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-insight-core-technology-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-insight-for-payment-analytics-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-insight-for-payment-fraud-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-insight-for-payment-monitoring-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-insight-services-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-it-channel-payment-operations-role-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-li-size {
	width: 32px;
	height: 32px;
}

.u-svg-icon-machine-learning-ai-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-managed-services-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-mobile-online-banking-channel-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-payment-card-network-solutions-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-payment-fraud-security-role-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-payment-processor-solutions-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-pos-channel-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-product-customization-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-product-videos-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-profile-email-size {
	width: 36px;
	height: 36px;
}

.u-svg-icon-profile-fb-size {
	width: 36px;
	height: 36px;
}

.u-svg-icon-profile-linkedin-size {
	width: 36px;
	height: 36px;
}

.u-svg-icon-protocol-decoding-correlation-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-real-time-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-real-time-data-collection-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-real-time-payments-channel-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-retail-bank-solutions-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-retailer-solutions-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-risk-scoring-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-search-size {
	width: 24px;
	height: 23.9px;
}

.u-svg-icon-self-hosted-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-training-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-tw-size {
	width: 32px;
	height: 32px;
}

.u-svg-icon-webinars-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-whitepapers-size {
	width: 65px;
	height: 65px;
}

.u-svg-icon-yt-size {
	width: 32px;
	height: 32px;
}

.u-svg-inetco-logo-size {
	width: 230.9px;
	height: 39.3px;
}

.u-svg-inetco-logo-with-tagline-size {
	width: 198.828px;
	height: 43.572px;
}

.u-svg-logo-size {
	width: 230.9px;
	height: 39.3px;
}

