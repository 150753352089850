// replace sass-rem function with a dummy function in local environments
// this allows us to have pixels in browsers on local instead of rems for easier testing
@function rem-calc($val, $base: 16) {
  $remSize: strip-unit($val / $base);
  @return #{$remSize}rem;
}
@function rem-calc-with-base($size, $base) {
  $remSize: strip-unit($size / $base);
  @return #{$remSize}rem;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function em-calc($size, $base) {
  $emSize: strip-unit($size / $base);
  @return #{$emSize}em;
}

@function lineheight-calc($size, $base) {
  $lineHeightSize: strip-unit($size / $base);
  @return #{$lineHeightSize};
}

@function get-color($color) {
  @return map-get($colors, $color);
}

@function get-gradient($gradient) {
  @return map-get($gradients, $gradient);
}

@function set-name($base, $size: null) {
  $klass: $base;
  @if $size != null {
    $klass: $klass + '-' + $size;
  }

  @return $klass;
}

// GRID
// We use these functions to calc grid
@function grid-calc($colNumber, $totalColumns) {
  $result: percentage(($colNumber / $totalColumns));
  @if $result == 0% {
    $result: 0;
  }
  @return $result;
}

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// MATH FUNCTIONS
@function pow($number, $exponent) {
  $value: 1;

  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  } @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: $value / $number;
    }
  }

  @return $value;
}

//SPACING
@function spacing($name,$scale: $space-scale) {
  @return 16px * map-get($scale,$name);
}