$_cols_number: 10;
$_col_width: 70;
$_gutter_width: 25;
$_is-env-dev: true;
/*!
Theme Name:   INETCO 2020
Theme URI:    https://www.inetco.com
Description:  Custom Wordpress Theme for INETCO
Version:      1.0
Author:       KodiakDev
Text Domain:  inetco
Domain Path:  /languages/
*/
// !Dependencies
@import 'dependencies';
@import 'vendors';

// !Site abstracts
@import 'abstracts/functions';
@import 'abstracts/grid-settings';
@import 'abstracts/variables/colors';
@import 'abstracts/variables/typography';
@import 'abstracts/variables/spacing';
@import 'abstracts/variables/responsive';
@import 'abstracts/mixins';

// !Base
@import 'base/normalize_tweaks';
@import 'base/base';
@import 'base/spacing';
@import 'base/textsize';
//@import 'base/form';
@import 'base/visibility';
@import 'base/common';

// !Generated
@import 'autogenerated/svg-sprite';

// !Components
@import 'components/grid';
@import 'components/button';
@import 'components/link';
@import 'components/nav';
//@import 'components/breadcrumb';
//@import 'components/carousel';
//@import 'components/contact';
//@import 'components/content_image';
//@import 'components/utilbar';
//@import 'components/reveal';
//@import 'components/member-login';
//@import 'components/panel';
//@import 'components/callout';
//@import 'components/logo-bar';
//@import 'components/testimonial';
//@import 'components/card';
@import 'components/social';
//@import 'components/icon-card';
//@import 'components/icon-block';
//@import 'components/icon-list';
//@import 'components/icon-badge';
//@import 'components/image-card';
//@import 'components/media-list';
//@import 'components/gallery';
//@import 'components/profile';
//@import 'components/qa';
//@import 'components/filter-bar';
//@import 'components/sidebar';
//@import 'components/search-result';

@import 'components/stack';
@import 'components/inline';
@import 'components/stack-inline';
// @import 'components/example-component/example-component';

// !Layout (non reusable components)
@import 'layout/main';
@import 'layout/header';
@import 'layout/footer';

// !Page (non reusable components)
//@import 'pages/page';
//@import 'pages/post-single';
//@import 'pages/page-notfound';
